<template>
  <p>Settings baby!</p>
</template>

<script>
  export default {
    name: 'TemplatesEditDesigner'
  }
</script>

<style lang="scss">

</style>
